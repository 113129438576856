<template>
  <b-overlay :show="loading" rounded="sm" no-fade class=" mt-2">
    <div>
      <div id="breadcrumb-alignment">
        <div id="menu" class="d-flex justify-content-start breadcrumb-wrapper bg-light  ">
          <b-breadcrumb>
            <b-breadcrumb-item v-for="(item, i) in folderTemp" :key="i" :class="item.active == true ? 'active' : ''" @click="menuClick(item)"> <feather-icon icon="HomeIcon" class="mr-50" v-if="i == 0" />{{ item.text }} </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>

      <b-button-group class="mt-2 mb-2">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="newFolder" variant="outline-primary">
          New Folder
        </b-button>
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="uploadFiles" variant="outline-primary">
          Upload Files
        </b-button>
      </b-button-group>

      <br />
      <b-row>
        <!-- <div cols="4" xl="1" sm="3" md="2" lg="2" v-for="x in projectData" v-if="x.folderId == folderId" :key="x.type + x.id"> -->

        <div class="ml-1 mr-1" v-for="x in projectData" v-if="x.folderId == folderId" :key="x.type + x.id">
          <b-card no-body bg-variant="light" @contextmenu.prevent="handleClick($event, x.type, x)" @click="itemClick(x)" style="max-width: 10rem; cursor:pointer" :img-src="x.type == 'folder' ? '/folder.png' : '/file.png'" img-alt="Image" img-top>
            <b-card-footer style="font-size:12px; text-align: center" v-if="x.type == 'folder'">{{ x.folderName }}</b-card-footer>
            <b-card-footer style="font-size:12px; text-align: center" v-if="x.type == 'file'">{{ x.fileName }}</b-card-footer>
          </b-card>
        </div>

        <vue-context ref="rightClickData">
          <li>
            <b-link v-for="data in rightClickData" :key="data.text" class="d-flex align-items-center" @click="optionClicked(data)">
              <feather-icon :icon="data.icon" size="16" />
              <span class="ml-75">{{ data.text }}</span>
            </b-link>
          </li>
        </vue-context>
      </b-row>

      <!-- Add New Folder -->
      <b-modal id="modal-new-folder" cancel-variant="outline-secondary" ok-title="Save Folder" @ok="handleOk" @show="resetModal" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New Folder">
        <b-form ref="fileRules">
          <b-form-group :state="folderNameState" invalid-feedback="File Name is required">
            <label for="fileName">Folder Name:</label>
            <b-form-input id="fileName" type="text" :state="folderNameState" v-model="modalFolderName" required placeholder="File Name" />
          </b-form-group>

          <b-form-group :state="companyState" v-if="userData.role == 'supermanager'" invalid-feedback="Company is required">
            <label for="company">Company:</label>
            <v-select id="company" style="width:100%;" v-model="modalCompany" :value="modalCompany" :reduce="(val) => val.id" :options="companyList" label="title" :state="companyState" required :clearable="false"> </v-select>
          </b-form-group>

          <b-form-group :state="userState" v-if="userData.role == 'supermanager'" invalid-feedback="User is required">
            <label for="user">User: </label>
            <v-select id="user" style="width:100%;" v-model="modalUser" :value="modalUser" :reduce="(val) => val.id" :options="tempUserList" :disabled="modalCompany == null" label="title" :state="userState" required :clearable="false"> </v-select>
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <!-- Add New Files -->
      <b-modal id="modal-new-file" cancel-variant="outline-secondary" ok-title="Save File" @ok="handleFileOk" @show="resetModal" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New Files">
        <b-form ref="fileRules">
          <b-form-group :state="fileState" invalid-feedback="File is required">
            <b-form-file @input="fileSelected" type="file" :state="fileState" multiple required placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
          </b-form-group>

          <b-form-group :state="companyState" v-if="userData.role == 'supermanager'" invalid-feedback="Company is required">
            <label for="fileName">Company:</label>
            <v-select style="width:100%;" v-model="modalCompany" :value="modalCompany" :reduce="(val) => val.id" :options="companyList" label="title" :state="companyState" required :clearable="false"> </v-select>
          </b-form-group>

          <b-form-group :state="userState" v-if="userData.role == 'supermanager'" invalid-feedback="User is required">
            <label for="fileName">User: </label>
            <v-select style="width:100%;" v-model="modalUser" :value="modalUser" :reduce="(val) => val.id" :options="tempUserList" :disabled="modalCompany == null" label="title" :state="userState" required :clearable="false"> </v-select>
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <!-- Edit Folder Name -->
      <b-modal id="modal-edit-folder" cancel-variant="outline-secondary" ok-title="Update Folder" @ok="handleFolderUpdate" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Edit Folder Name">
        <b-form ref="fileRules">
          <b-form-group :state="folderNameState" invalid-feedback="Folder Name is required">
            <label for="fileName">Folder Name:</label>
            <b-form-input id="fileName" type="text" :state="folderNameState" v-model="modalFolderName" required placeholder="File Name" />
          </b-form-group>

          <b-form-group :state="companyState" v-if="userData.role == 'supermanager'" invalid-feedback="Company is required">
            <label for="fileName">Company:</label>
            <v-select style="width:100%;" v-model="modalCompany" :value="modalCompany" :reduce="(val) => val.id" :options="companyList" label="title" :state="companyState" required :clearable="false"> </v-select>
          </b-form-group>

          <b-form-group :state="userState" v-if="userData.role == 'supermanager'" invalid-feedback="User is required">
            <label for="fileName">User: </label>
            <v-select style="width:100%;" v-model="modalUser" :value="modalUser" :reduce="(val) => val.id" :options="tempUserList" :disabled="modalCompany == null" label="title" :state="userState" required :clearable="false"> </v-select>
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <!-- Edit File Name -->
      <b-modal id="modal-edit-file" cancel-variant="outline-secondary" ok-title="Update File" @ok="handleFileUpdate" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Edit File Name">
        <b-form ref="fileRules">
          <b-form-group :state="fileNameState" invalid-feedback="File Name is required">
            <label for="fileName">File Name:</label>
            <b-form-input id="fileName" type="text" :state="fileNameState" v-model="modalFileName" required placeholder="File Name" />
          </b-form-group>

          <b-form-group :state="companyState" v-if="userData.role == 'supermanager'" invalid-feedback="Company is required">
            <label for="fileName">Company:</label>
            <v-select style="width:100%;" v-model="modalCompany" :value="modalCompany" :reduce="(val) => val.id" :options="companyList" label="title" :state="companyState" required :clearable="false"> </v-select>
          </b-form-group>

          <b-form-group :state="userState" v-if="userData.role == 'supermanager'" invalid-feedback="User is required">
            <label for="fileName">User: </label>
            <v-select style="width:100%;" v-model="modalUser" :value="modalUser" :reduce="(val) => val.id" :options="tempUserList" :disabled="modalCompany == null" label="title" :state="userState" required :clearable="false"> </v-select>
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import projectStoreModule from '../projectStoreModule';
import vSelect from 'vue-select';
import axios from 'axios';
import axiosIns from '@/libs/axios';
import VueContext from 'vue-context';
import router from '@/router';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BBreadcrumb } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    VueContext,
    BBreadcrumb,
  },

  directives: {
    Ripple,
  },

  setup() {
    const PROJECT_APP_STORE_MODULE_NAME = 'project';
    // Register module
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      rightClickData: [],
      userList: [],
      tempUserList: [
        {
          id: -1,
          title: 'All Users',
        },
      ],
      companyList: [],
      loading: false,
      fileURL: store.state.app.fileURL,
      level: 0,
      folderId: 0,
      modalFolderName: null,
      modalFileName: null,
      modalFile: [],
      modalUser: -1,
      modalCompany: null,
      selectFolderId: null,
      selectFileId: null,
      percentCompleted: 0,
      fileURL: store.state.app.fileURL,
      userData: JSON.parse(localStorage.getItem('userData')),

      download: false,
      folderTemp: [
        {
          text: 'Projects',
          id: 0,
          active: true,
        },
      ],

      projectData: [],
    };
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('userShow', val);
      },
    },

    modalCompany: {
      handler: function(val, before) {
        if (val == null) {
          this.modalUser = -1;
        }
        if (before != null) {
          this.modalUser = -1;
        }
        if (val != -1) {
          this.tempUserList = [];
          this.userList.forEach((element) => {
            if (element.companyId == val) {
              this.tempUserList.push(element);
            }
          });

          this.tempUserList.unshift({
            id: -1,
            title: 'All Users',
          });
        } else {
          this.tempUserList = this.userList;
        }
      },
    },
  },

  methods: {
    onRowSelected(item) {
      router.push({ name: 'user-edit', params: { id: item[0].id } });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    newFolder(val) {
      this.$bvModal.show('modal-new-folder');
    },

    uploadFiles(val) {
      this.$bvModal.show('modal-new-file');
    },

    handleClick(event, type, item) {
      if (type == 'folder') {
        this.rightClickData = [
          { icon: 'FileIcon', text: 'Open', data: item },
          { icon: 'EditIcon', text: 'Edit', data: item },
          { icon: 'XIcon', text: 'Delete', data: item },
        ];
        this.$refs.rightClickData.open(event, item);
      } else if (type == 'file') {
        this.rightClickData = [
          { icon: 'EditIcon', text: 'Edit', data: item },
          { icon: 'XIcon', text: 'Delete', data: item },
          { icon: 'SaveIcon', text: 'Save', data: item },
        ];

        this.$refs.rightClickData.open(event, item);
      }
    },

    menuClick(val) {
      this.folderId = val.id;

      if (val.id == 0) {
        this.folderTemp = [
          {
            text: 'Projects',
            id: 0,
            active: true,
          },
        ];
      } else {
        var tempFolder = [];
        var finished = false;
        this.folderTemp.forEach((element) => {
          if (finished == false) {
            if (element.id == val.id) {
              tempFolder.push({
                text: element.text,
                id: element.id,
                active: true,
              });
              this.folderTemp = tempFolder;
              finished = true;
            } else {
              tempFolder.push(element);
            }
          }
        });
      }
    },

    optionClicked(val) {
      switch (val.text) {
        case 'Open':
          this.itemClick(val.data);
          break;

        case 'Edit':
          if (val.data.type == 'folder') {
            this.modalFolderName = val.data.folderName;
            this.modalUser = val.data.userId;
            this.selectFolderId = val.data.id;
            this.modalCompany = val.data.companyId;

            this.$bvModal.show('modal-edit-folder');
          } else {
            this.modalFileName = val.data.fileName;
            this.modalUser = val.data.userId;
            this.selectFileId = val.data.id;
            this.modalCompany = val.data.companyId;

            this.$bvModal.show('modal-edit-file');
          }

          break;

        case 'Save':
          // const url = window.URL.createObjectURL(new Blob([this.fileURL + val.data.fileLink]));
          this.loading = true;
          axiosIns
            .post('getDownload', { link: val.data.fileLink }, { responseType: 'blob' })
            .then((res) => {
              const url = window.URL.createObjectURL(new Blob([res.data]));

              const link = document.createElement('a');
              link.href = url;

              link.setAttribute('download', val.data.fileName);

              document.body.appendChild(link);
              link.click();
              this.loading = false;
            })
            .catch((error) => {
              console.log(error);
            });

          // const url = fetch(this.fileURL + val.data.fileLink).then((response) => response.blob());

          // window.location.href = this.fileURL + val.data.fileLink;
          break;

        case 'Delete':
          if (val.data.type == 'file') {
            this.$swal({
              title: 'Are you sure?',
              text: '',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, delete it!',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ml-1',
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.loading = true;

                axiosIns
                  .delete(`deleteProjectFile/${val.data.id}`)
                  .then((res) => {
                    this.projectData = res.data;
                    this.totalRows = res.data.length;

                    this.loading = false;
                  })
                  .catch((error) => {
                    console.log('error delete ', error);
                    this.loading = false;
                  });
              }
            });
          } else {
            this.$swal({
              title: 'Are you sure?',
              text: '',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, delete it!',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ml-1',
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.loading = true;

                axiosIns
                  .delete(`deleteProjectFolder/${val.data.id}`)
                  .then((res) => {
                    this.projectData = res.data;
                    this.totalRows = res.data.length;

                    this.loading = false;
                  })
                  .catch((error) => {
                    console.log('error delete ', error);
                    this.loading = false;
                  });
              }
            });
          }

          break;

        default:
          break;
      }
    },

    itemClick(val) {
      if (val.type == 'folder') {
        this.folderTemp.forEach((element) => {
          element.active = false;
        });
        this.folderTemp.push({
          id: val.id,
          text: val.folderName,
          active: true,
        });

        this.topMenuId = val.id;
        this.folderId = val.id;
      }
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      var data = {
        userId: this.modalUser,
        folderName: this.modalFolderName,
        topMenuId: this.folderId,
        companyId: this.modalCompany,
        status: 'A',
      };
      // Trigger submit handler

      this.modalOk(data);
    },

    handleFolderUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      var data = {
        id: this.selectFolderId,
        userId: this.modalUser,
        folderName: this.modalFolderName,
        topMenuId: this.folderId,
        companyId: this.modalCompany,
        status: 'A',
      };
      // Trigger submit handler

      this.modalFolderUpdate(data);
    },

    handleFileUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      var data = {
        id: this.selectFileId,
        userId: this.modalUser,
        fileName: this.modalFileName,
        topMenuId: this.folderId,
        companyId: this.modalCompany,
        status: 'A',
      };
      // Trigger submit handler

      this.modalFileUpdate(data);
    },

    handleFileOk(bvModalEvent) {
      // Prevent modal from closing

      bvModalEvent.preventDefault();

      if (this.percentCompleted == 100) {
        var data = {
          userId: this.modalUser,
          topMenuId: this.folderId,
          fileLink: this.modalFile,
          companyId: this.modalCompany,
          status: 'A',
        };
        // Trigger submit handler

        this.modalFileOk(data);
      }
    },

    modalOk(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-new-folder');
      });

      this.loading = true;

      store
        .dispatch('project/saveFolder', data)
        .then((res) => {
          this.projectData = res.data;
          this.modalFolderName = null;
          this.modalUser = -1;
          this.modalCompany = null;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    modalFolderUpdate(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-edit-folder');
      });

      this.loading = true;

      store
        .dispatch('project/updateFolder', data)
        .then((res) => {
          this.projectData = res.data;
          this.modalFolderName = null;
          this.modalUser = -1;
          this.modalCompany = null;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    modalFileUpdate(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-edit-file');
      });

      this.loading = true;

      store
        .dispatch('project/updateFile', data)
        .then((res) => {
          this.projectData = res.data;
          this.modalFileName = null;
          this.modalUser = -1;
          this.modalCompany = null;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    modalFileOk(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-new-file');
      });

      this.loading = true;

      store
        .dispatch('project/saveFile', data)
        .then((res) => {
          this.projectData = res.data;
          this.modalFolderName = null;
          this.modalUser = -1;
          this.modalCompany = null;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    resetModal() {
      this.tempUserList = [
        {
          id: -1,
          title: 'All Users',
        },
      ];

      this.modalFolderName = null;
      this.modalFile = [];
      this.modalUser = -1;
      this.percentCompleted = 0;
      this.modalCompany = null;
    },

    checkFormValidity() {
      const nameValid = this.$refs.fileRules.checkValidity();
      if (this.userData.role == 'user') {
        return nameValid;
      } else {
        return nameValid && this.modalCompany != null && this.modalUser != null;
      }
    },

    fileSelected(event) {
      //  this.loading = true;
      var total = event.length;
      event.forEach((element, i) => {
        let formData = new FormData();

        formData.append('file', element);

        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * (100 - (event.length + 1))) / progressEvent.total);
          }.bind(this),
        };

        axiosIns
          .post('uploadProjectFile', formData, config)
          .then((response) => {
            this.modalFile.push(response.data);

            total = total - 1;

            if (total == 0) {
              this.percentCompleted = 100;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      });
    },

    fetchFolders() {
      this.loading = true;
      store
        .dispatch('project/fetchFolders', [])
        .then((res) => {
          this.projectData = res.data;

          this.dropdownCompanyAll();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dropdownUserAll() {
      store
        .dispatch('project/dropdownUserAll', [])
        .then((res) => {
          res.data.unshift({
            id: -1,
            title: 'All User',
          });
          this.userList = res.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dropdownCompanyAll() {
      store
        .dispatch('project/dropdownCompanyAll', [])
        .then((res) => {
          res.data.unshift({
            id: -1,
            title: 'All Companies',
          });
          this.companyList = res.data;
          this.dropdownUserAll();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {
    this.fetchFolders();
  },

  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },

    folderNameState() {
      return this.modalFolderName != null && this.modalFolderName != '' ? true : false;
    },

    fileNameState() {
      return this.modalFileName != null && this.modalFileName != '' ? true : false;
    },

    fileState() {
      return this.modalFile != null && this.modalFile != '' ? true : false;
    },

    companyState() {
      return this.modalCompany != null && this.modalCompany != '' ? true : false;
    },

    userState() {
      return this.modalUser != null && this.modalUser != '' ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.card:hover {
  transform: scale(1.1);
}

.card {
  transition: transform 0.2s ease;
}

.card {
  box-shadow: 0 4px 6px 0 rgba(22, 22, 26, 0.18);
  border-radius: 0;
  border: 0;
}

.active a {
  color: #6e6b7b;
  cursor: auto;
  text-decoration: none;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue-context/dist/css/vue-context.css';
</style>
